import React from 'react';
import './Login.css';
import Circle from '../Common/Circle/Circle';
import { errorType, RequestPOST, set_error_message } from '../Request';
import { Movement } from '../Materials/Exam/Movement/Movement';
import { AxiosError } from 'axios';
import { url_login_user } from '../public';
const Login: React.FC = () => {
  const [code, setCode] = React.useState<number | null>(null);
  const [nationality_id, setNationality_id] = React.useState<number | null>(
    null,
  );
  const [encode, setEncode] = React.useState<string | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [alert, setAalert] = React.useState<errorType>([]);

  const SetError = (error: AxiosError): void => {
    setAalert(set_error_message(error));
    setLoading(false);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const Download = (): void => {
    window.location.href = '/gate/material/';
  };
  React.useEffect(() => {
    if (loading && code && nationality_id) {
      const bodyFormData = new FormData();
      bodyFormData.append('code', `${code}`);
      bodyFormData.append('nationality_id', `${nationality_id}`);
      if (encode) bodyFormData.append('encode', encode);
      RequestPOST(url_login_user, bodyFormData, Download, SetError);
    }
  }, [loading]);

  return (
    <div className="login">
      {alert.length === 0 && !loading ? (
        <>
          <Circle Arrow>
            <div className="login_title">
              <p>تسجيل الدخول</p>
              <p>قم بمليء الحقول التالية لاتمام تسجيل دخولك</p>
            </div>
          </Circle>
          <form
            className="LoginForm"
            onSubmit={e => {
              e.preventDefault();
              setLoading(true);
              setAalert([]);
            }}
          >
            <input
              value={code ?? ''}
              onChange={e =>
                setCode(
                  e.target.value === '' ? null : Number(e.target.value ?? null),
                )
              }
              type="number"
              name="code"
              placeholder="كود الطالب"
              required
            />
            <input
              value={nationality_id ?? ''}
              onChange={e =>
                setNationality_id(
                  e.target.value === '' ? null : Number(e.target.value ?? null),
                )
              }
              type="number"
              name="nationality_id"
              placeholder="رقم البطاقة"
              required
            />
            <input
              onChange={e => setEncode(e.target.value)}
              value={encode ?? ''}
              type="text"
              name="encode"
              placeholder="كود حجز الملازم"
            />
            <button type="submit">تسجيل الدخول</button>
          </form>
        </>
      ) : alert.length === 0 && loading ? (
        <Circle loading>
          <p className="sending_title">جاري تسجيل الدخول</p>
        </Circle>
      ) : alert.length !== 0 && !loading ? (
        <Circle
          Arrow
          forceDown
          buttons={
            <Movement direction="previous" OnMovement={() => setAalert([])} />
          }
        >
          <div className="error">
            {alert.map((error, index) => (
              <p key={index}>{error as string}</p>
            ))}
          </div>
        </Circle>
      ) : null}
    </div>
  );
};

export default Login;
