import React from 'react';
import './Circle.css';
import Arrow from './Arrow/Arrow';

type PopupProps = {
  children: React.ReactNode;
  buttons?: React.ReactNode;
  loading?: true;
} & (
  | {
      forceDown?: boolean;
      Arrow: true;
    }
  | {
      forceDown?: undefined;
      Arrow?: undefined;
    }
);
const Circle: React.FC<PopupProps> = props => {
  return (
    <div className="Circle_container">
      <div className="Circle">
        {props.loading && <div className="loading"></div>}
        <div className="sub-circle">{props.children}</div>
      </div>
      {props.Arrow && <Arrow forceDown={props.forceDown} />}
      {props.buttons}
    </div>
  );
};

export default Circle;
