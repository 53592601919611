import React from 'react';
import './ListMaterials.css';
import Circle from '../../Common/Circle/Circle';
import { Movement } from '../Exam/Movement/Movement';

type ListMaterialsProps<valueType extends string> = {
  lists: valueType[];
  title: string;
  onSelectObj: (obj: valueType) => void;
  onPrevious?: () => void;
};

const ListMaterial = <valueType extends string>(
  props: ListMaterialsProps<valueType>,
) => {
  const [apperance, setApperance] = React.useState<'visible' | 'hidden'>(
    'hidden',
  );

  React.useEffect(() => {
    setApperance('hidden');
    setTimeout(() => {
      setApperance('visible');
    }, 5);
  }, [props.title]);
  return apperance === 'visible' ? (
    <div className="ListMaterial">
      <Circle
        Arrow
        buttons={
          props.onPrevious && (
            <Movement
              direction="previous"
              OnMovement={() => {
                props.onPrevious?.();
              }}
            />
          )
        }
      >
        <p className="ListMaterial_title">{props.title}</p>
      </Circle>
      <div className="list">
        {props.lists.map(obj => (
          <p onClick={() => props.onSelectObj(obj)} key={obj}>
            {obj}
          </p>
        ))}
      </div>
    </div>
  ) : null;
};

export default ListMaterial;
