import React from 'react';
import './Exam.css';
import Qestion from './Qestion/Qestion';
import Circle from '../../Common/Circle/Circle';
import { EnsureBoutton, Movement } from './Movement/Movement';
import { AxiosError } from 'axios';
import {
  errorType,
  RequestGET,
  RequestPOST,
  set_error_message,
} from '../../Request';
import { url_studentGate } from '../../public';

interface PopupProps {
  examId: number;
  materialName: string;
  ExamName: string;
}
//////////////////////////////////////////////////////////////////////////////
export interface questionType {
  id: number;
  head: string;
  A: string;
  B: string;
  C: string | null;
  D: string | null;
}
//////////////////////////////////////////////////////////////////////////////
enum StatusType {
  download = 'download',
  Exam = 'Exam',
  ensure = 'ensure',
  sending = 'sending',
  done = 'done',
}
//////////////////////////////////////////////////////////////////////////////
const Exam: React.FC<PopupProps> = props => {
  const [status, setStatus] = React.useState<StatusType>(StatusType.download);
  const [questionNumber, setQuestionNumber] = React.useState(0);
  const [questions, setQuestions] = React.useState<questionType[]>([]);
  const [alert, setAlert] = React.useState<errorType>([]);
  const [choosedQuestions, setChoosedQuestions] = React.useState<{
    [QuestingNumber: number]: 'A' | 'B' | 'C' | 'D';
  }>({});

  React.useEffect(() => {
    const handleError = (error: AxiosError): void => {
      setAlert(set_error_message(error));
      if (status === StatusType.download) setStatus(StatusType.Exam);
      if (status === StatusType.sending) setStatus(StatusType.done);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleDownload = (data: questionType[]): void => {
      if (data.length === 0) setAlert(['الامتحان فارغ']);
      setStatus(StatusType.Exam);
      setQuestions(data);
    };
    const requestOptions = {
      params: {
        main_target: 'Questions',
        examId: props.examId,
      },
    };
    if (status === StatusType.download)
      RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
    if (status === StatusType.sending) {
      const bodyFormData = new FormData();
      bodyFormData.append('choosedQuestions', JSON.stringify(choosedQuestions));
      bodyFormData.append('examId', `${props.examId}`);
      bodyFormData.append('main_target', 'Questions');
      RequestPOST(
        url_studentGate,
        bodyFormData,
        () => {
          setStatus(StatusType.done);
        },
        handleError,
      );
    }
  }, [status]);

  const closeAlert = React.useCallback(() => {
    setAlert([]);
    if (status === StatusType.Exam) setStatus(StatusType.download);
    else if (status === StatusType.done) setStatus(StatusType.ensure);
  }, [status]);

  const question = React.useMemo<questionType | null>(() => {
    return questions.length ? questions[questionNumber] : null;
  }, [questionNumber, questions]);

  return alert.length === 0 && status === StatusType.download ? (
    <Circle loading>
      <p className="sending_title">جاري جلب البيانات</p>
    </Circle>
  ) : alert.length !== 0 ? (
    <Circle
      Arrow
      forceDown
      buttons={
        <Movement direction="previous" OnMovement={() => closeAlert()} />
      }
    >
      <div className="error">
        {alert.map((error, index) => (
          <p key={index}>{error as string}</p>
        ))}
      </div>
    </Circle>
  ) : status === StatusType.Exam ? (
    question && (
      <Qestion
        materialName={props.materialName + ' ' + props.ExamName}
        questionNumber={questionNumber + 1}
        questionsCount={questions.length}
        {...question}
        choice={choosedQuestions[question.id]}
        onMovement={(direction: 'next' | 'previous' | 'ensure') => {
          if (direction === 'next') {
            setQuestionNumber(questionNumber + 1);
          } else if (direction === 'ensure') {
            setStatus(StatusType.ensure);
          } else if (direction === 'previous') {
            setQuestionNumber(questionNumber - 1);
          }
        }}
        onSelectChoice={(choice: 'A' | 'B' | 'C' | 'D') => {
          setChoosedQuestions({
            ...choosedQuestions,
            [question.id]: choice,
          });
        }}
      />
    )
  ) : status === StatusType.ensure ? (
    <Circle
      Arrow
      forceDown
      buttons={
        <>
          <Movement
            direction="previous"
            OnMovement={() => setStatus(StatusType.Exam)}
          />
          <EnsureBoutton OnMovement={() => setStatus(StatusType.sending)} />
        </>
      }
    >
      <p className="ensure_title">
        هل انت متأكد من انتهاء الامتحان وتم حل عدد السؤال{' '}
        {Object.keys(choosedQuestions).length} من اصل {questions.length}
      </p>
    </Circle>
  ) : status === StatusType.sending ? (
    <Circle loading>
      <p className="sending_title">جاري ارسال الاجابات</p>
    </Circle>
  ) : status === StatusType.done ? (
    <Circle
      Arrow
      forceDown
      buttons={
        <Movement
          direction="next"
          OnMovement={() => {
            window.location.reload();
          }}
        />
      }
    >
      <p className="done_title">تم الانتهاء من الامتحان</p>
    </Circle>
  ) : null;
};

export default Exam;
