import React from 'react';
import './Qestion.css';
import Circle from '../../../Common/Circle/Circle';
import { EnsureBoutton, Movement } from '../Movement/Movement';
import { questionType } from '../Exam';

const Qestion: React.FC<
  questionType & {
    questionNumber: number;
    questionsCount: number;
    materialName: string;
    choice?: 'A' | 'B' | 'C' | 'D';
    onSelectChoice: (choice: 'A' | 'B' | 'C' | 'D') => void;
    onMovement: (direction: 'next' | 'previous' | 'ensure') => void;
  }
> = props => {
  const buttons = React.useMemo(() => {
    let next;
    let previous;
    if (props.questionNumber < props.questionsCount) {
      next = (
        <Movement
          direction="next"
          OnMovement={() => props.onMovement('next')}
        />
      );
    }
    if (props.questionNumber > 1) {
      previous = (
        <Movement
          direction="previous"
          OnMovement={() => props.onMovement('previous')}
        />
      );
    }
    if (props.questionNumber === props.questionsCount) {
      next = <EnsureBoutton OnMovement={() => props.onMovement('ensure')} />;
    }
    return (
      <>
        {next} {previous}
      </>
    );
  }, [props.questionNumber, props.questionNumber]);

  const [apperance, setApperance] = React.useState<'visible' | 'hidden'>(
    'hidden',
  );

  React.useEffect(() => {
    setApperance('hidden');
    setTimeout(() => {
      setApperance('visible');
    }, 5);
  }, [props.head]);
  return apperance === 'visible' ? (
    <div className="Qestion">
      <Circle Arrow buttons={buttons}>
        <p className="Qestion_title">
          مادة {props.materialName} ... السؤال {props.questionNumber} من{' '}
          {props.questionsCount}
        </p>
        <p className="Qestion_title">{props.head}</p>
      </Circle>
      <div className="choices">
        <p
          className={props.choice === 'A' ? 'selected' : ''}
          onClick={() => props.onSelectChoice('A')}
        >
          {props.A}
        </p>
        <p
          className={props.choice === 'B' ? 'selected' : ''}
          onClick={() => props.onSelectChoice('B')}
        >
          {props.B}
        </p>
        {props.C && (
          <p
            className={props.choice === 'C' ? 'selected' : ''}
            onClick={() => props.onSelectChoice('C')}
          >
            {props.C}
          </p>
        )}
        {props.D && (
          <p
            className={props.choice === 'D' ? 'selected' : ''}
            onClick={() => props.onSelectChoice('D')}
          >
            {props.D}
          </p>
        )}
      </div>
    </div>
  ) : null;
};

export default Qestion;
