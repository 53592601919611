import React from 'react';
import './Arrow.css';

const Arrow: React.FC<{ forceDown?: boolean }> = props => {
  return (
    <div className={'Arrow' + (props.forceDown ? ' forceDown' : '')}>
      <div className="Arrowhead"></div>
    </div>
  );
};

export default Arrow;
