import axios, { AxiosResponse, AxiosError } from 'axios';
import { domain } from './public';

///////////////////////////////////////////
// export interface bodyFormDataType {
//     [key: string]: string | null | number | boolean | undefined | bodyFormDataType[] | bodyFormDataType;
// }
/////////////////////////////////////
export type errorType = string[] | { [pk_index: number]: string }[];
/////////////////////////////////////
export const set_error_message = (error: AxiosError): errorType => {
  let error_message: errorType = [];
  try {
    const response: AxiosResponse | null = error.response
      ? error.response
      : null;
    if (response?.status === 0 || response?.status === undefined) {
      return ['تأكد من اتصالك بالانترنت'];
    }
    if (
      JSON.stringify(response?.data.errors)?.includes('plz login') &&
      response?.config.method?.toLowerCase() === 'get'
    ) {
      window.location.href = '/gate/login/';
    }
    error_message = response?.data.errors
      ? response?.data.errors
      : ['خطأ داخل السيرفر'];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_err) {
    error_message = ['خطأ داخل السيرفر'];
  }

  return error_message;
};

///////////////////////////////  Request  ///////////////////////////////
export function RequestGET<Tparams, ResponseType>(
  path: string,
  bodyFormData: Tparams,
  requestDone: (data: ResponseType, params: Tparams) => void,
  SetError: (error: AxiosError) => void,
) {
  const config = {
    headers: {
      // user: 'OMRAN',
    },
  };
  const url = domain + path;
  axios
    .get(url, { ...config, ...bodyFormData })
    .then((response: AxiosResponse) => {
      const data = response.data.data;
      requestDone(data, bodyFormData);
    })
    .catch((error: AxiosError) => {
      SetError(error);
    });
}
///////////////////////////////////////////////////////////////////////////////////
export function RequestPOST<ResponseType>(
  path: string,
  bodyFormData: FormData,
  requestDone: (data: ResponseType, params: FormData) => void,
  SetError: (error: AxiosError) => void,
) {
  const config = {
    headers: {
      // user: 'OMRAN',
    },
  };
  const url = domain + path;
  axios
    .post(url, bodyFormData, config)
    .then((response: AxiosResponse) => {
      const data = response.data.data;
      requestDone(data, bodyFormData);
    })
    .catch((error: AxiosError) => {
      SetError(error);
    });
}
