import React from 'react';
import './Movement.css';

type PopupProps = {
  OnMovement: (direction: 'next' | 'previous' | 'ensureBoutton') => void;
};

export const Movement: React.FC<
  PopupProps & { direction: 'next' | 'previous' }
> = props => {
  return (
    <svg
      className={props.direction}
      onClick={() => props.OnMovement(props.direction)}
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m150 550h775v100h-775z" />
      <path d="m710 935-70-70 265-265-265-265 70-70 335 335z" />
    </svg>
  );
};

export const EnsureBoutton: React.FC<PopupProps> = props => {
  return (
    <p
      className="ensureBoutton"
      onClick={() => props.OnMovement('ensureBoutton')}
    >
      انهاء الامتحان
    </p>
  );
};
