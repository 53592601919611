import React from 'react';
import './Materials.css';
import ListMaterial from './ListMaterials/ListMaterials';
import Exam from './Exam/Exam';
import { errorType, RequestGET, set_error_message } from '../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../public';
import Circle from '../Common/Circle/Circle';
import { Movement } from './Exam/Movement/Movement';
type LevelType = '1' | '2' | '3' | '4';
const Levels: Record<LevelType, string> = {
  '1': 'الاول' as const,
  '2': 'الثاني' as const,
  '3': 'الثالث' as const,
  '4': 'الرابع' as const,
};
type ResponseType = {
  Levels: {
    [level in LevelType]: {
      material_name: string;
      drive: string | null;
      exams: { id: number; name: string; degree: number | null }[];
    }[];
  };
  studentName: string;
};
const Material: React.FC = () => {
  const [selectLevel, setSelectLevel] = React.useState<LevelType | null>(null);
  const [selectMaterial, setselectMaterial] = React.useState<string | null>(
    null,
  );
  const [selectExam, setselectExam] = React.useState<string | null>(null);
  const [response, setResponse] = React.useState<ResponseType | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);

  React.useEffect(() => {
    const handleError = (error: AxiosError): void => {
      setAlert(set_error_message(error));
      setLoading(false);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleDownload = (data: ResponseType): void => {
      setResponse(data);
      setLoading(false);
    };
    const requestOptions = {
      params: {
        main_target: 'materials',
      },
    };
    if (loading)
      RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, [loading]);

  const closeAlert = React.useCallback(() => {
    setAlert([]);
    setLoading(true);
  }, []);

  const levelsData = React.useMemo<ResponseType['Levels']>(() => {
    if (response) return response.Levels;
    else return {} as ResponseType['Levels'];
  }, [response]);

  const listMaterials = React.useMemo(() => {
    if (levelsData && selectLevel !== null)
      return levelsData[selectLevel].map(material => material.material_name);
    else return [];
  }, [selectLevel]);

  const Material = React.useMemo(() => {
    if (levelsData && selectLevel !== null && selectMaterial !== null)
      return (
        levelsData[selectLevel].find(material => {
          return material.material_name === selectMaterial;
        }) ?? null
      );
    else return null;
  }, [selectLevel, selectMaterial]);

  const listExams = React.useMemo(() => {
    if (Material !== null) return Material.exams;
    else return [];
  }, [Material]);
  return (
    <div className="Materials">
      {alert.length === 0 && loading ? (
        <Circle loading>
          <p className="sending_title">جاري جلب البيانات</p>
        </Circle>
      ) : alert.length !== 0 && !loading ? (
        <Circle
          Arrow
          forceDown
          buttons={
            <Movement direction="previous" OnMovement={() => closeAlert()} />
          }
        >
          <div className="error">
            {alert.map((error, index) => (
              <p key={index}>{error as string}</p>
            ))}
          </div>
        </Circle>
      ) : levelsData ? (
        selectLevel === null ? (
          <ListMaterial<LevelType>
            lists={
              Object.keys(levelsData).map(
                level => `${Levels[level as LevelType]}`,
              ) as LevelType[]
            }
            title={`مرحبا بك ${response?.studentName} .. قم باختيار المستوى الدراسي`}
            onSelectObj={levelString => {
              Object.entries(Levels).forEach(([key, value]) => {
                if (value === levelString) setSelectLevel(key as LevelType);
              });
            }}
          />
        ) : selectMaterial === null ? (
          <ListMaterial<string>
            lists={listMaterials}
            title={
              listMaterials.length !== 0
                ? `قم باختيار المادة في المستوى ${Levels[selectLevel]}`
                : `لا يوجد مواد في المستوى ${Levels[selectLevel]}`
            }
            onSelectObj={setselectMaterial}
            onPrevious={() => setSelectLevel(null)}
          />
        ) : selectExam === null ? (
          <ListMaterial<string>
            lists={
              [
                ...listExams
                  .filter(exam => exam.degree === null)
                  .map(exam => exam.name),
                ,
                ...(Material && Material.drive ? ['محتوي المادة'] : []),
              ] as string[]
            }
            title={
              (listExams.filter(exam => exam.degree === null).length !== 0
                ? `قم باختيار الامتحان في مادة ${selectMaterial} في المستوى ${Levels[selectLevel]}` +
                  (Material?.drive ? ' او اختر محتوي المادة' : '')
                : `لا يوجد امتحانات لك في مادة ${selectMaterial}`) +
              ` .. تم تسليم عدد امتحانات  ${listExams.reduce((acc, exam) => acc + (exam.degree !== null ? 1 : 0), 0)}`
            }
            onSelectObj={(exam: string) => {
              if (Material && Material.drive && exam === 'محتوي المادة') {
                window.open(Material.drive, '_blank');
              } else {
                setselectExam(exam);
              }
            }}
            onPrevious={() => setselectMaterial(null)}
          />
        ) : selectExam ? (
          <Exam
            examId={
              levelsData[selectLevel]
                .find(material => material.material_name === selectMaterial)
                ?.exams.find(exam => exam.name === selectExam)?.id ?? 0
            }
            materialName={selectMaterial}
            ExamName={selectExam}
          />
        ) : null
      ) : null}
    </div>
  );
};

export default Material;
