import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Background from './Common/Background/Background';
import Login from './Login/Login';
import Materials from './Materials/Materials';
function App() {
  return (
    <Background>
      <BrowserRouter>
        <Routes>
          <Route path="/gate/login/" element={<Login />} />
          <Route path="/gate/material/" element={<Materials />} />
          <Route path="*" element={<Navigate to="/gate/material/" />} />
        </Routes>
      </BrowserRouter>
    </Background>
  );
}

export default App;
